/**
 * Additional Classes
 */
%typo-upper {
  text-transform: uppercase;
}

%typo-no-underline {
  &, &:hover, &:focus {
    text-decoration: none;
  }
}

/**
 * Font Families
 */
%typo-sans {
  font-family: $font-family-sans;
}

/**
 * Font Weights
 */
%typo-300 {
  font-weight: 300;

  &i {
    font-weight: 300;
    font-style: italic;
  }
}

%typo-400 {
  font-weight: 400;

  &i {
    font-weight: 400;
    font-style: italic;
  }
}

%typo-600 {
  font-weight: 600;

  &i {
    font-weight: 600;
    font-style: italic;
  }
}

/**
 * Mixin - Transform a not numeric value to number
 **/
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/**
 * Mixin - Calculate font-size depending by screen width
 * @include fluid-type(breakpoint-min(), breakpoint-max(), min-fontsize, max-fontsize);
 */
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @include media-breakpoint-up(sm) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @include media-breakpoint-up(xl) {
        font-size: $max-font-size;
      }
    }
  }
}

/**
 * Mixin - Typography
 * @include typo(typo-serif, typo-700, min_fontsize (eg 12px), max_fontsize (eg 24px), $color, $line-height (1.5em), is_uppercase (default 0), min_breakpoint (default sm-min), max_breakpoint (default xl-min));
 **/
@mixin typo($family, $weight, $min-size, $max-size, $color, $line-height-with-em, $typo-upper: 0, $min_width: breakpoint-min(sm), $max_width: breakpoint-min(xl)) {
  @extend %#{$family};
  @extend %#{$weight};
  @include fluid-type($min_width, $max_width, $min-size, $max-size);
  color: $color;
  line-height: #{$line-height-with-em};
  @if $typo-upper == 1 {
    @extend %typo-upper;
  }
}

// default
%txt-default, .txt-default {
  @include typo(typo-sans, typo-400, $fz-16, $fz-20, $color-white, 1.45em);
}

.txt-title {
  @include typo(typo-sans, typo-600, $fz-46, $fz-85, $color-white, 1.1em);
}