// Webfont font path
$icon-font-path: "../fonts/";

// Grid settings
$main-md-columns: 12;
$sidebar-md-columns: 4;

// Colors
$color-white: #FFFFFF;
$color-blue: #172C3D;
$color-black: #000000;
$brand-primary: $color-white;

$color_0: rgb(255,255,255);
$color_1: rgb(255,255,255);
$color_2: rgb(0,0,0);
$color_3: rgb(237,28,36);
$color_4: rgb(0,136,203);
$color_5: rgb(255,203,5);
$color_6: rgb(114,114,114);
$color_7: rgb(176,176,176);
$color_8: rgb(255,255,255);
$color_9: rgb(114,114,114);
$color_10: rgb(176,176,176);
$color_11: rgb(5,33,55);
$color_12: rgb(10,66,110);
$color_13: rgb(80,117,146);
$color_14: rgb(127,158,182);
$color_15: rgb(169,197,219);
$color_16: rgb(43,48,50);
$color_17: rgb(85,95,99);
$color_18: rgb(128,143,149);
$color_19: rgb(171,181,184);
$color_20: rgb(209,217,220);
$color_21: rgb(38,37,34);
$color_22: rgb(75,73,68);
$color_23: rgb(113,110,102);
$color_24: rgb(160,158,153);
$color_25: rgb(208,206,201);
$color_26: rgb(13,29,39);
$color_27: rgb(27,58,77);
$color_28: rgb(82,107,122);
$color_29: rgb(130,152,166);
$color_30: rgb(176,198,211);
$color_31: rgb(0,46,84);
$color_32: rgb(0,91,168);
$color_33: rgb(95,146,190);
$color_34: rgb(141,179,211);
$color_35: rgb(175,207,233);

// Main Font size
$fz-13: 13px;
$fz-14: 14px;
$fz-15: 15px;
$fz-17: 17px;
$fz-22: 22px;
$fz-24: 24px;
$fz-30: 30px;
$fz-32: 32px;

$fz-16: 16px;
$fz-18: 18px;
$fz-20: 20px;
$fz-46: 46px;
$fz-85: 85px;
$font-size-base: 1.25rem; // 20px

// Font Family
$font-family-sans: 'Montserrat', sans-serif;
$font-family-base: $font-family-sans;

// Transitions
$transition-default: 0.3s ease-out;

// Header
$py: 8px;
$py-sm: 12px;
$py-md: 20px;

$brand-height: 40px;
$brand-height-sm: 52px;
$brand-height-md: 62px;

/* stylelint-disable */

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
);

/* stylelint-enable */
