// video background
.video-background {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;

  &:before {
    @include pseudo();
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparentize($color-blue, 0.5);
    z-index: 8;
  }

  &:after {
    @include pseudo();
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(./../images/pattern.png);
    background-repeat: repeat;
    z-index: 10;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-block {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

.logo-small-header {
  width: auto;
  height: 50px;
}

.logo-d-header {
  width: 230px;
  height: auto;
  @include media-breakpoint-up(sm) {
    width: 320px;
  }
}

.mediabeats-about {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.header-square-link {
  width: 60px;
  height: 60px;
  border: 2px solid $color-white;
  @extend %typo-no-underline;

  img {
    height: 30px;
    width: auto;
  }
}

// site body
.double-wrap-inline {

}

.mediabeats-payoff {
  margin-top: 36px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 36px;
  }
  .txt-title {
    line-height: 1;
  }
  .txt-default {
    line-height: 1.2;
  }
}

.separator-x-white {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  width: $grid-gutter-width*3;
  height: 1px;
  background: $color-white;
}

.mediabeats-videoAR-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#videoAR {
  //max-width: 100%;
  //height: auto;
}

.mediabeats-service {
  height: 265px;
  text-align: center;
  &-icon {
    height: 74px;
    margin-top: 49px;
    margin-bottom: 20px;
  }
  &-name {
    margin-bottom: 10px;
    font-family: $font-family-sans;
    font-size: $fz-18;
    font-weight: 900;
    color: $color-white;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  &-bgcolor-27 {
    background-color: $color_27;
  }
  &-bgcolor-28 {
    background-color: $color_28;
  }
  &-bgcolor-29 {
    background-color: $color_29;
  }
  &-bgcolor-30 {
    background-color: $color_30;
  }
}

.mediabeats-info {
  font-family: $font-family-sans;
  font-size: $fz-20;
  font-weight: 400;
  line-height: 1.4em;
  color: $color-white;
  &-activity {
    &-container {
      min-height: auto;
      @include media-breakpoint-up(xl) {
        min-height: 430px;
      }
    }
    margin-top: 101px;
    @include media-breakpoint-only(lg) {
      margin-top: 0;
    }
    margin-left: 7px;
    margin-right: 7px;
    padding-bottom: 80px;
    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
    &-video-container {
      height: 430px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
  &-brand {
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 111px;
    @include media-breakpoint-up(lg) {
      min-height: 400px;
      margin-left: 91px;
      margin-right: 91px;
      margin-bottom: 0;
    }
    margin-top: 111px;
    .separator-x-white {
      background: $color-28;
    }
    color: $color-28;
    &-image {
      background-image: url("../images/desk.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
}

.mediabeats-footer {
  min-height: 240px;
  padding-bottom: 50px;
  background-color: $color_27;
  &-contacts {
    padding-top: 50px;
    font-size: $fz-17;
    line-height: 1.2em;
    color: $color-white;
    strong {
      font-weight: 600;
    }
  }
  &-logo {
    padding-top: 50px;
  }
}