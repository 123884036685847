// Pesudo elemnts
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

// Placeholder text color
@mixin placeholderColor($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Color hover
@mixin colorHover($color) {
  &:hover {
    color: $color;
  }
}

// Link on hover
@mixin linkOnHoverCenter($before-or-after, $bottom, $height, $color, $start, $end) {
  position: relative;
  text-decoration: none !important;
  &:#{$before-or-after} {
    @include media-breakpoint-up(md) {
      position: absolute;
      display: block;
      content: '';
      bottom: $bottom;
      left: $start;
      right: $start;
      height: $height;
      background-color: transparentize($color, 0.2);
      transition: all 0.2s ease-out;
      opacity: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    &:hover:#{$before-or-after} {
      left: $end;
      right: $end;
      opacity: 1;
    }
  }
}

// Keyframe prefix
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Get column width of current resolution
@mixin currentColumnWidth($column) {
  $column-sm: $container-max-widths (sm) / $grid-columns;
  $column-md: $container-max-widths (md) / $grid-columns;
  $column-lg: $container-max-widths (lg) / $grid-columns;
  $column-xl: $container-max-widths (xl) / $grid-columns;
  $column-xxl: $container-max-widths (xxl) / $grid-columns;

  position: relative;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @include media-breakpoint-up(sm) {
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    width: $column-sm * $column;
  }
  @include media-breakpoint-up(lg) {
    width: $column-md * $column;
  }
  @include media-breakpoint-up(xl) {
    width: $column-lg * $column;
  }
  @include media-breakpoint-up(xxl) {
    width: $column-xl * $column;
  }
}

// Column count
@mixin columnCount($count) {
  -webkit-column-count: $count; /* Chrome, Safari, Opera */
  -moz-column-count: $count; /* Firefox */
  column-count: $count;
}

// Column gap
@mixin columnGap($gap) {
  -webkit-column-gap: $gap; /* Chrome, Safari, Opera */
  -moz-column-gap: $gap; /* Firefox */
  column-gap: $gap;
}

// Text Shadow Function
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}

// Text Shadow Mixin
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}
