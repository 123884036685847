* {
  // text antialiasing
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  @extend %txt-default;
  position: relative;
  //&:before {
  //  @include pseudo();
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  background: -moz-linear-gradient(top, rgba(23,44,61,0) 0%, rgba(23,44,61,0.4) 21%, rgba(23,44,61,1) 54%);
  //  background: -webkit-linear-gradient(top, rgba(23,44,61,0) 0%,rgba(23,44,61,0.4) 21%,rgba(23,44,61,1) 54%);
  //  background: linear-gradient(to bottom, rgba(23,44,61,0) 0%,rgba(23,44,61,0.4) 21%,rgba(23,44,61,1) 54%);
  //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00172c3d', endColorstr='#172c3d',GradientType=0 );
  //  z-index: 30;
  //}
  background: $color_27;

  > div.wrap.container-fluid {
    position: relative;
    z-index: 40;
  }
}

main.main {
  @include make-col-ready();
}

.bkg-blue {
  background: $color-blue;
}